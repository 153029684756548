












































































































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import {
  email,
  sameAs,
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import api from "@/core/utils/api";

type InvInfo = {
  companyName: string;
  seminarName: string;
  retrieved?: boolean;
};

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]*)(?=.{6,})/;
@Component({
  mixins: [validationMixin],
  validations: {
    firstNameInput: { required, minLength: minLength(2) },
    lastNameInput: { required, minLength: minLength(2) },
    emailInput: { required, email },
    passwordInput: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(16),
    },
    confirmPasswordInput: {
      required,
      sameAsPassword: sameAs("passwordInput"),
    },
    tosAgreement: {
      sameAs: sameAs(() => true),
    },
  },
})
export default class Register extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/registerUser") registerUser!: (data: any) => Promise<void>;

  showPass = false;
  firstNameInput = "";
  lastNameInput = "";
  companyNameInput = "";
  emailInput = "";
  passwordInput = "";
  confirmPasswordInput = "";
  tosAgreement = false;

  // query
  emailDisabled = false;
  invitationToken: string | null = null;

  get firstNameErrors() {
    const errors: any[] = [];
    if (!this.$v.firstNameInput!.$dirty) return errors;
    !this.$v.firstNameInput!.required &&
      errors.push(this.$t("registerSection.fName"));
    !this.$v.firstNameInput!.minLength &&
      errors.push(this.$t("registerSection.fNameMin"));
    return errors;
  }

  get lastNameErrors() {
    const errors: any[] = [];
    if (!this.$v.lastNameInput!.$dirty) return errors;
    !this.$v.lastNameInput!.required &&
      errors.push(this.$t("registerSection.lName"));
    !this.$v.lastNameInput!.minLength &&
      errors.push(this.$t("registerSection.lNameMin"));
    return errors;
  }

  get emailErrors() {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.required &&
      errors.push(this.$t("registerSection.email"));
    !this.$v.emailInput!.email &&
      errors.push(this.$t("registerSection.invalidEmail"));
    return errors;
  }

  get passwordErrors() {
    const errors: any[] = [];
    if (!this.$v.passwordInput!.$dirty) return errors;
    !this.$v.passwordInput!.required &&
      errors.push(this.$t("registerSection.password"));
    !this.$v.passwordInput!.minLength &&
      errors.push(this.$t("registerSection.passMin"));
    !this.$v.passwordInput!.maxLength &&
      errors.push(this.$t("registerSection.passMax"));
    if (!passwordRegex.test(this.passwordInput))
      errors.push(this.$t("registerSection.passRules"));
    return errors;
  }

  get confirmPasswordErrors() {
    const errors: any[] = [];
    if (!this.$v.confirmPasswordInput!.$dirty) return errors;
    !this.$v.confirmPasswordInput!.required &&
      errors.push(this.$t("registerSection.confirmPass"));
    !this.$v.confirmPasswordInput!.sameAsPassword &&
      errors.push(this.$t("registerSection.passMismatch"));
    return errors;
  }

  get agreementErrors() {
    const errors: any[] = [];
    if (!this.$v.confirmPasswordInput!.$dirty) return errors;
    !this.$v.tosAgreement!.sameAs &&
      errors.push(this.$t("registerSection.tOS"));
    return errors;
  }

  submit() {
    this.$v.$touch();
    if (!this.$v.$invalid && this.passwordErrors.length === 0) {
      this.clearEverything(false);
      let data = {
        email: this.emailInput,
        password: this.passwordInput,
        firstName: this.firstNameInput.trim(),
        lastName: this.lastNameInput.trim(),
        companyName: this.companyNameInput.trim(),
        invitationToken: this.invitationToken,
      };
      this.registerUser(data);
    }
  }

  beforeDestroy() {
    this.emailInput = "";
    this.passwordInput = "";
    this.confirmPasswordInput = "";
  }

  getting = false;
  invitation: InvInfo = {
    companyName: "",
    seminarName: "",
  };
  async getInfo() {
    this.getting = true;
    try {
      const end = `/api/Invitations/${this.emailInput}/${this.invitationToken}`;
      this.invitation = await api.get(end);
      this.invitation.retrieved = false;
    } catch (error) {
      console.log(error);
    }
    this.getting = false;
  }

  mounted() {
    const { query } = this.$route;
    if (
      typeof query.email === "string" &&
      typeof query.invitationToken === "string"
    ) {
      this.invitationToken = query.invitationToken;
      this.emailInput = query.email;
      this.emailDisabled = true;
      this.getInfo();
    }
  }
}
